import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import { alpha } from "@mui/system/colorManipulator";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import { UserButton } from "../../user-button";
import { ContactsButton } from "../contacts-button";
import { LanguageSwitch } from "../language-switch";
import { NotificationsButton } from "../notifications-button";
import { SearchButton } from "../search-button";
import type { Theme } from "@mui/material/styles/createTheme";
import type { FC } from "react";
const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;
interface TopNavProps {
  onMobileNavOpen?: () => void;
}
export const TopNav: FC<TopNavProps> = props => {
  const {
    onMobileNavOpen,
    ...other
  } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  return <Box component="header" sx={{
    backdropFilter: "blur(6px)",
    backgroundColor: theme => alpha(theme.palette.background.default, 0.8),
    position: "sticky",
    left: {
      lg: `${SIDE_NAV_WIDTH}px`
    },
    top: 0,
    width: {
      lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
    },
    zIndex: theme => theme.zIndex.appBar
  }} {...other} data-sentry-element="Box" data-sentry-component="TopNav" data-sentry-source-file="top-nav.tsx">
			<Stack alignItems="center" direction="row" justifyContent="space-between" spacing={2} sx={{
      minHeight: TOP_NAV_HEIGHT,
      px: 2
    }} data-sentry-element="Stack" data-sentry-source-file="top-nav.tsx">
				<Stack alignItems="center" direction="row" spacing={2} data-sentry-element="Stack" data-sentry-source-file="top-nav.tsx">
					{!lgUp && <IconButton onClick={onMobileNavOpen}>
							<SvgIcon>
								<Menu01Icon />
							</SvgIcon>
						</IconButton>}
					<SearchButton data-sentry-element="SearchButton" data-sentry-source-file="top-nav.tsx" />
				</Stack>
				<Stack alignItems="center" direction="row" spacing={2} data-sentry-element="Stack" data-sentry-source-file="top-nav.tsx">
					<LanguageSwitch data-sentry-element="LanguageSwitch" data-sentry-source-file="top-nav.tsx" />
					<NotificationsButton data-sentry-element="NotificationsButton" data-sentry-source-file="top-nav.tsx" />
					<ContactsButton data-sentry-element="ContactsButton" data-sentry-source-file="top-nav.tsx" />
					<UserButton data-sentry-element="UserButton" data-sentry-source-file="top-nav.tsx" />
				</Stack>
			</Stack>
		</Box>;
};