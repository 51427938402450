import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { NavColor } from "~/types/settings";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import type { Theme } from "@mui/material/styles/createTheme";
import type { FC, ReactNode } from "react";
import type { Section } from "../config";
const SIDE_NAV_WIDTH = 280;
const VerticalLayoutRoot = styled("div")(({
  theme
}) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH
  }
}));
const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%"
});
interface VerticalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
}
export const VerticalLayout: FC<VerticalLayoutProps> = props => {
  const {
    children,
    sections,
    navColor
  } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();
  return <>
			<TopNav onMobileNavOpen={mobileNav.handleOpen} data-sentry-element="TopNav" data-sentry-source-file="vertical-layout.tsx" />
			{lgUp && <SideNav color={navColor} sections={sections} />}
			{!lgUp && <MobileNav color={navColor} onClose={mobileNav.handleClose} open={mobileNav.open} sections={sections} />}
			<VerticalLayoutRoot data-sentry-element="VerticalLayoutRoot" data-sentry-source-file="vertical-layout.tsx">
				<VerticalLayoutContainer data-sentry-element="VerticalLayoutContainer" data-sentry-source-file="vertical-layout.tsx">{children}</VerticalLayoutContainer>
			</VerticalLayoutRoot>
		</>;
};