import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { NavColor } from "~/types/settings";
import { MobileNav } from "../mobile-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import type { Theme } from "@mui/material/styles/createTheme";
import type { FC, ReactNode } from "react";
import type { Section } from "../config";
const HorizontalLayoutRoot = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%"
});
const HorizontalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%"
});
interface HorizontalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
}
export const HorizontalLayout: FC<HorizontalLayoutProps> = props => {
  const {
    children,
    navColor,
    sections
  } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();
  return <>
			<TopNav color={navColor} onMobileNav={mobileNav.handleOpen} sections={sections} data-sentry-element="TopNav" data-sentry-source-file="horizontal-layout.tsx" />
			{!lgUp && <MobileNav color={navColor} onClose={mobileNav.handleClose} open={mobileNav.open} sections={sections} />}
			<HorizontalLayoutRoot data-sentry-element="HorizontalLayoutRoot" data-sentry-source-file="horizontal-layout.tsx">
				<HorizontalLayoutContainer data-sentry-element="HorizontalLayoutContainer" data-sentry-source-file="horizontal-layout.tsx">{children}</HorizontalLayoutContainer>
			</HorizontalLayoutRoot>
		</>;
};