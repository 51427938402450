import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import Bell01Icon from "@untitled-ui/icons-react/build/esm/Bell01";
import { useCallback, useMemo, useState } from "react";
import { usePopover } from "~/hooks/use-popover";
import { notifications as initialNotifications } from "./notifications";
import { NotificationsPopover } from "./notifications-popover";
import type { FC } from "react";
import type { Notification } from "./notifications";
const useNotifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>(initialNotifications);
  const unread = useMemo((): number => {
    return notifications.reduce((acc, notification) => acc + (notification.read ? 0 : 1), 0);
  }, [notifications]);
  const handleRemoveOne = useCallback((notificationId: string): void => {
    setNotifications(prevState => {
      return prevState.filter(notification => notification.id !== notificationId);
    });
  }, []);
  const handleMarkAllAsRead = useCallback((): void => {
    setNotifications(prevState => {
      return prevState.map(notification => ({
        ...notification,
        read: true
      }));
    });
  }, []);
  return {
    handleMarkAllAsRead,
    handleRemoveOne,
    notifications,
    unread
  };
};
export const NotificationsButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>();
  const {
    handleRemoveOne,
    handleMarkAllAsRead,
    notifications,
    unread
  } = useNotifications();
  return <>
			<Tooltip title="Notifications" data-sentry-element="Tooltip" data-sentry-source-file="notifications-button.tsx">
				<IconButton ref={popover.anchorRef} onClick={popover.handleOpen} data-sentry-element="IconButton" data-sentry-source-file="notifications-button.tsx">
					<Badge color="error" badgeContent={unread} data-sentry-element="Badge" data-sentry-source-file="notifications-button.tsx">
						<SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="notifications-button.tsx">
							<Bell01Icon data-sentry-element="Bell01Icon" data-sentry-source-file="notifications-button.tsx" />
						</SvgIcon>
					</Badge>
				</IconButton>
			</Tooltip>
			<NotificationsPopover anchorEl={popover.anchorRef.current} notifications={notifications} onClose={popover.handleClose} onMarkAllAsRead={handleMarkAllAsRead} onRemoveOne={handleRemoveOne} open={popover.open} data-sentry-element="NotificationsPopover" data-sentry-source-file="notifications-button.tsx" />
		</>;
};