import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import { useDialog } from "~/hooks/use-dialog";
import { SearchDialog } from "./search-dialog";
import type { FC } from "react";
export const SearchButton: FC = () => {
  const dialog = useDialog();
  return <>
			<Tooltip title="Search" data-sentry-element="Tooltip" data-sentry-source-file="search-button.tsx">
				<IconButton onClick={dialog.handleOpen} data-sentry-element="IconButton" data-sentry-source-file="search-button.tsx">
					<SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="search-button.tsx">
						<SearchMdIcon data-sentry-element="SearchMdIcon" data-sentry-source-file="search-button.tsx" />
					</SvgIcon>
				</IconButton>
			</Tooltip>
			<SearchDialog onClose={dialog.handleClose} open={dialog.open} data-sentry-element="SearchDialog" data-sentry-source-file="search-button.tsx" />
		</>;
};