import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import SvgIcon from "@mui/material/SvgIcon";
import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import { usePopover } from "~/hooks/use-popover";
import { useMeCache } from "~/queries/useMe";
import { UserPopover } from "./user-popover";
import type { FC } from "react";
export const UserButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>();
  const {
    data
  } = useMeCache();
  return <>
			<Box component={ButtonBase} onClick={popover.handleOpen} ref={popover.anchorRef} sx={{
      alignItems: "center",
      display: "flex",
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "divider",
      height: 40,
      width: 40,
      borderRadius: "50%"
    }} data-sentry-element="Box" data-sentry-source-file="user-button.tsx">
				<Avatar sx={{
        height: 32,
        width: 32
      }} src={data?.avatar ?? undefined} data-sentry-element="Avatar" data-sentry-source-file="user-button.tsx">
					<SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="user-button.tsx">
						<User01Icon data-sentry-element="User01Icon" data-sentry-source-file="user-button.tsx" />
					</SvgIcon>
				</Avatar>
			</Box>
			<UserPopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} data-sentry-element="UserPopover" data-sentry-source-file="user-button.tsx" />
		</>;
};