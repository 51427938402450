import { withAuthGuard } from "~/hocs/with-auth-guard";
import { useSettings } from "~/hooks/use-settings";
import { useSections } from "./config";
import { HorizontalLayout } from "./horizontal-layout";
import { VerticalLayout } from "./vertical-layout";
import type { FC, ReactNode } from "react";
interface LayoutProps {
  children?: ReactNode;
}
export const Layout: FC<LayoutProps> = withAuthGuard(props => {
  const settings = useSettings();
  const sections = useSections();
  if (settings.layout === "horizontal") {
    return <HorizontalLayout sections={sections} navColor={settings.navColor} {...props} />;
  }
  return <VerticalLayout sections={sections} navColor={settings.navColor} {...props} />;
});