import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useGetCurrentCompanyCache } from "~/queries/useGetCurrentCompany";
import type { SxProps } from "@mui/system/styleFunctionSx";
import type { FC } from "react";
interface TenantSwitchProps {
  sx?: SxProps;
}
export const TenantSwitch: FC<TenantSwitchProps> = props => {
  const {
    data
  } = useGetCurrentCompanyCache();
  return <Stack {...props} data-sentry-element="Stack" data-sentry-component="TenantSwitch" data-sentry-source-file="tenant-switch.tsx">
			<Typography textAlign="center" color="inherit" variant="h6" gutterBottom data-sentry-element="Typography" data-sentry-source-file="tenant-switch.tsx">
				{data?.name}
			</Typography>
			<Typography textAlign="center" color="neutral.400" variant="body2" data-sentry-element="Typography" data-sentry-source-file="tenant-switch.tsx">
				{data?.address}
			</Typography>
		</Stack>;
};