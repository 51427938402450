import { Url } from "next/dist/shared/lib/router/router";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { paths } from "~/paths";

export interface AppQuery {
	settingsTab?: "user" | "company" | "team" | "customs";
	action?: "create" | "update" | "delete";
	dataType?:
		| "product_category"
		| "product_option"
		| "product_option_group"
		| "file";
	userId?: string;
	agentQuery?: string;
	agentId?: string;
	clientQuery?: string;
	clientId?: string;
	productId?: string;
	productQueryKeyword?: string;
	productCategoryId?: string;
	productOptionId?: string;
	productOptionGroupId?: string;
	fileId?: string;
	processId?: string;
}

export const useAppRouter = () => {
	const router = useRouter();
	const appQuery = useMemo<AppQuery>(() => router.query, [router.query]);

	const goToCreateNewUser = () => {
		return router.push({
			pathname: paths.settings,
			query: {
				...router.query,
				action: "create",
			},
		});
	};

	const goToUpdateUser = (id: string) => {
		return router.push({
			pathname: paths.settings,
			query: {
				...router.query,
				action: "update",
				userId: id,
			},
		});
	};

	const goToDeleteUser = (id: string) => {
		return router.push({
			pathname: paths.settings,
			query: {
				...router.query,
				action: "delete",
				userId: id,
			},
		});
	};

	const goToUpdateAgentQuery = (query: string) => {
		return router.push({
			pathname: paths.agents.list,
			query: {
				...router.query,
				agentQuery: query,
			},
		});
	};

	const goToUpdateClientQuery = (query: string) => {
		return router.push({
			pathname: paths.clients.list,
			query: {
				...router.query,
				clientQuery: query,
			},
		});
	};

	const goToCreateProductCategoryQuery = () => {
		return router.push({
			query: {
				...router.query,
				action: "create",
				dataType: "product_category",
			},
		});
	};

	const goToCreateProductOptionQuery = () => {
		return router.push({
			query: {
				...router.query,
				action: "create",
				dataType: "product_option",
			},
		});
	};

	const goToProductFiltersQuery = (obj: { keyword?: string }) => {
		return router.push({
			query: {
				...router.query,
				productQueryKeyword: obj.keyword ?? "",
			},
		});
	};

	const goToUpdateProductCategoryQuery = (id: string) => {
		return router.push({
			query: {
				...router.query,
				action: "update",
				dataType: "product_category",
				productCategoryId: id,
			},
		});
	};

	const goToUpdateProductOptionQuery = (id: string) => {
		return router.push({
			query: {
				...router.query,
				action: "update",
				dataType: "product_option",
				productOptionId: id,
			},
		});
	};

	const goToSettingsTab = (newTab: NonNullable<AppQuery["settingsTab"]>) => {
		return router.push({
			query: {
				...router.query,
				settingsTab: newTab,
			},
		});
	};

	const getAgentDetailsQuery = (id: string): Url => {
		return {
			query: {
				...router.query,
				agentId: id,
			},
		};
	};

	const getClientDetailsQuery = (id: string): Url => {
		return {
			query: {
				...router.query,
				clientId: id,
			},
		};
	};

	const getProductDetailsQuery = (id: string): Url => {
		return {
			query: {
				...router.query,
				productId: id,
			},
		};
	};

	const goToCreateProductOptionGroupQuery = () => {
		return router.push({
			query: {
				...router.query,
				action: "create",
				dataType: "product_option_group",
			},
		});
	};

	const goToUpdateProductOptionGroupQuery = (id: string) => {
		return router.push({
			query: {
				...router.query,
				action: "update",
				dataType: "product_option_group",
				productOptionGroupId: id,
			},
		});
	};

	const goToFileDetailsQuery = (id: string) => {
		return router.push({
			query: {
				...router.query,
				fileId: id,
			},
		});
	};

	const goToCreateFileQuery = (processId?: string) => {
		return router.push({
			query: {
				...router.query,
				action: "create",
				dataType: "file",
				processId,
			},
		});
	};

	return {
		goToCreateFileQuery,
		goToUpdateProductOptionGroupQuery,
		goToCreateProductOptionGroupQuery,
		getProductDetailsQuery,
		getClientDetailsQuery,
		getAgentDetailsQuery,
		goToCreateNewUser,
		goToUpdateUser,
		goToDeleteUser,
		goToUpdateAgentQuery,
		goToUpdateClientQuery,
		goToCreateProductCategoryQuery,
		goToCreateProductOptionQuery,
		goToProductFiltersQuery,
		goToUpdateProductCategoryQuery,
		goToUpdateProductOptionQuery,
		goToSettingsTab,
		goToFileDetailsQuery,
		appQuery,
		router,
		currentActions: {
			isViewingFile: !!appQuery.fileId,
			isCreatingFile:
				appQuery.action === "create" && appQuery.dataType === "file",
		},
	};
};
