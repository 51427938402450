import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { browserStorage } from "~/libs/localforage";
import { tokens } from "~/locales/tokens";
import type { FC } from "react";
type Language = "en" | "de" | "es" | "cn";
type LanguageOptions = Record<Language, {
  icon: string;
  label: string;
}>;
const languageOptions: LanguageOptions = {
  en: {
    icon: "/assets/flags/flag-uk.svg",
    label: "English"
  },
  de: {
    icon: "/assets/flags/flag-de.svg",
    label: "German"
  },
  es: {
    icon: "/assets/flags/flag-es.svg",
    label: "Spanish"
  },
  cn: {
    icon: "/assets/flags/flag-cn.svg",
    label: "Chinese"
  }
};
interface LanguagePopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}
export const LanguagePopover: FC<LanguagePopoverProps> = props => {
  const {
    anchorEl,
    onClose,
    open = false,
    ...other
  } = props;
  const {
    i18n,
    t
  } = useTranslation();
  const handleChange = useCallback(async (language: Language): Promise<void> => {
    onClose?.();
    await i18n.changeLanguage(language);
    await browserStorage.language.set(language);
    const message = t(tokens.common.languageChanged);
    toast.success(message);
  }, [onClose, i18n, t]);
  return <Popover anchorEl={anchorEl} anchorOrigin={{
    horizontal: "right",
    vertical: "bottom"
  }} disableScrollLock transformOrigin={{
    horizontal: "right",
    vertical: "top"
  }} onClose={onClose} open={open} PaperProps={{
    sx: {
      width: 220
    }
  }} {...other} data-sentry-element="Popover" data-sentry-component="LanguagePopover" data-sentry-source-file="language-popover.tsx">
			{(Object.keys(languageOptions) as Language[]).map(language => {
      const option = languageOptions[language];
      return <MenuItem onClick={() => handleChange(language)} key={language}>
						<ListItemIcon>
							<Box sx={{
            width: 28,
            "& img": {
              width: "100%"
            }
          }}>
								<img alt={option.label} src={option.icon} />
							</Box>
						</ListItemIcon>
						<ListItemText primary={<Typography variant="subtitle2">{option.label}</Typography>} />
					</MenuItem>;
    })}
		</Popover>;
};