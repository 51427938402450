import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { RouterLink } from "~/components/router-link";
import { Scrollbar } from "~/components/scrollbar";
import { usePathname } from "~/hooks/use-pathname";
import { paths } from "~/paths";
import { useGetCurrentCompanyCache } from "~/queries/useGetCurrentCompany";
import type { NavColor } from "~/types/settings";
import { TenantSwitch } from "../tenant-switch";
import { MobileNavSection } from "./mobile-nav-section";
import type { FC } from "react";
import type { Section } from "../config";
const MOBILE_NAV_WIDTH = 280;
const useCssVars = (color: NavColor): Record<string, string> => {
  const theme = useTheme();
  return useMemo((): Record<string, string> => {
    switch (color) {
      // Blend-in and discrete have no difference on mobile because
      // there's a backdrop and differences are not visible
      case "blend-in":
      case "discrete":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.neutral[100],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400]
          };
        } else {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.text.primary,
            "--nav-logo-border": theme.palette.neutral[100],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.secondary,
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900]
          };
        }
      case "evident":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400]
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400]
          };
        }
      default:
        return {};
    }
  }, [theme, color]);
};
interface MobileNavProps {
  color?: NavColor;
  onClose?: () => void;
  open?: boolean;
  sections?: Section[];
}
export const MobileNav: FC<MobileNavProps> = props => {
  const {
    color = "evident",
    open,
    onClose,
    sections = []
  } = props;
  const pathname = usePathname();
  const cssVars = useCssVars(color);
  const {
    data
  } = useGetCurrentCompanyCache();
  return <Drawer anchor="left" onClose={onClose} open={open} PaperProps={{
    sx: {
      ...cssVars,
      backgroundColor: "var(--nav-bg)",
      color: "var(--nav-color)",
      width: MOBILE_NAV_WIDTH
    }
  }} variant="temporary" data-sentry-element="Drawer" data-sentry-component="MobileNav" data-sentry-source-file="mobile-nav.tsx">
			<Scrollbar sx={{
      height: "100%",
      "& .simplebar-content": {
        height: "100%"
      },
      "& .simplebar-scrollbar:before": {
        background: "var(--nav-scrollbar-color)"
      }
    }} data-sentry-element="Scrollbar" data-sentry-source-file="mobile-nav.tsx">
				<Stack sx={{
        height: "100%"
      }} data-sentry-element="Stack" data-sentry-source-file="mobile-nav.tsx">
					<Stack alignItems="center" spacing={2} sx={{
          p: 3
        }} data-sentry-element="Stack" data-sentry-source-file="mobile-nav.tsx">
						<Box component={RouterLink} href={paths.index} data-sentry-element="Box" data-sentry-source-file="mobile-nav.tsx">
							{data ? <img style={{
              width: "60px",
              maxWidth: "100vw",
              maxHeight: "60px",
              aspectRatio: "1/1",
              objectFit: "cover",
              objectPosition: "center",
              borderRadius: 8
            }} src={data.logo ?? undefined} alt="Company Logo" /> : <Box sx={{
              borderColor: "var(--nav-logo-border)",
              borderRadius: 1,
              borderStyle: "solid",
              borderWidth: 1,
              display: "flex",
              height: 60,
              p: "4px",
              width: 60
            }}>
									<img src="/favicon.png" width="60px" />
								</Box>}
						</Box>
						<TenantSwitch sx={{
            flexGrow: 1
          }} data-sentry-element="TenantSwitch" data-sentry-source-file="mobile-nav.tsx" />
					</Stack>
					<Stack component="nav" spacing={2} sx={{
          flexGrow: 1,
          px: 2
        }} data-sentry-element="Stack" data-sentry-source-file="mobile-nav.tsx">
						{sections.map((section, index) => <MobileNavSection items={section.items} key={index} pathname={pathname} subheader={section.subheader} />)}
					</Stack>
				</Stack>
			</Scrollbar>
		</Drawer>;
};